<template>
  <v-autocomplete
    v-model="searchInput"
    :items="searchItems"
    eager
    :append-icon="null"
    :disabled="disabled"
    :loading="isLoadingSearchResults"
    return-object
    hide-details
    item-text="value"
    item-value="key"
    no-filter
    hide-selected
    :search-input.sync="searchQuery"
    @update:search-input="doSearch"
    name="tag-search"
    type="search"
    flat
    solo
    chips
    deletable-chips
    multiple
    @change="handleInput"
    :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
    label="Search by topic"
    class="rounded-r-0"
  >
    <!-- show loader while the data is loading -->
    <template v-slot:no-data>
      <div v-if="isLoadingSearchResults" class="text-center py-3">
        <v-progress-circular
          :width="3"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else class="text-center py-2">
        No results found.
      </div>
    </template>

    <!-- show user profile list for search results -->
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item
        ripple
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ item.value }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
// The filter name to be used in Vuex store
const filterName = "relevance"

// The default state of the form inputs
const defaultState = () => ({
  searchQuery: "",
  isLoadingSearchResults: false,

  searchInput: [],
  searchItems: [],

  // for handling debounce on type
  _timerId: null
})

// Export the SFC
export default {
  // Name of the component
  name: "RelevanceSearch",

  // Accept incoming data from parent
  props: {
    platform: {
      type: String,
      required: true
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  // Initiate the state variables
  data: () => defaultState(),

  // Register the computable properties
  computed: {
    /**
     * Get a list of all of them
     *
     * @returns {Array}
     */
    appliedFilters() {
      return this.$store.getters["influencerDiscovery/findFiltersByType"](filterName)
    },
  },

  // Define the local method functions
  methods: {
    /**
     * Make API request to fetch the select options for autocomplete
     *
     * @returns {void}
     */
    doSearch() {
      // cancel pending call
      if (this._timerId) {
        clearTimeout(this._timerId)
      }

      // delay new call 500ms
      this._timerId = setTimeout(async () => {
        // if input is empty, don't continue
        if (!this.searchQuery) return

        // if a request is being made, wait for it
        if (this.isLoadingSearchResults) return

        // show the loader while request is being made
        this.isLoadingSearchResults = true

        try {
          const queryParams = new window.URLSearchParams({
            q: this.searchQuery,
            platform: this.platform
          })

          const response = await axios({ url: "/api/filters/topics?" + queryParams })

          // the API responds like
          // also keep searchInputs at the end
          this.searchItems.splice(
            0,
            this.searchItems.length,
            ...response.data
              .map((item) => ({ ...item, key: Symbol() })),
            ...this.searchInput
          )
        } catch (error) {
          // log using the helper function
          logger({ type: "Network Error", error })
        } finally {
          this.isLoadingSearchResults = false
        }
      }, 500)
    },

    /**
     * let word cloud component know the current items
     *
     * @returns {void}
     */
    dispatchEventToWordCloud() {
      // emit an event for word-cloud component to fetch the results
      window.dispatchEvent(new CustomEvent('relevanceSearchUpdate', {
        detail: {
          module: "influencerDiscovery",
          origin: "RelevanceSearch/handleInput",
          items: this.searchInput
        }
      }))
    },

    /**
     * Emit the event and push the filter
     *
     * @returns {void}
     */
    handleInput() {
      // clear the search input
      this.searchQuery = ""
      this.searchItems.splice(0, this.searchItems.length, ...this.searchInput)

      // go through all of the current values and apply the filter
      for (const item of this.searchInput) {
        // find if it's not already in applied filter's list
        const index = this.appliedFilters.findIndex((search) => search.data.inputs.key === item.key)

        // if found, don't add it again
        if (index !== -1) continue

        // otherwise add it to filters list
        this.$store.dispatch("influencerDiscovery/addFilter", {
          type: filterName,
          data: {
            color: "purple lighten-5",
            icon: "tag",
            iconColor: "purple",
            text: `Relevance #${item.tag}`,
            inputs: item
          }
        })
      }

      // Once the words have been updated, delete them locally
      for (const appliedFilter of this.appliedFilters) {
        const index = this.searchInput.findIndex((search) => search.key === appliedFilter.data.inputs.key)

        if (index === -1) {
          this.$store.dispatch("influencerDiscovery/removeFilter", appliedFilter.id)
        }
      }

      this.dispatchEventToWordCloud()
    },

    /**
     * Handle the event when the platform changes
     *
     * @param {Event} e
     * @returns {void}
     */
    handlePlatformChange(e) {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery") return

      // reset the state
      Object.assign(this.$data, defaultState())
    },

    /**
     * Handle the event when the filter is removed
     *
     * @param {Event} e
     * @returns {void}
     */
    handleRemoveFilter(e) {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery" || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      const index = this.searchInput.findIndex((search) => e.detail.item.data.inputs.key === search.key)

      if (index !== -1) {
        // delete the element from local state
        this.searchInput.splice(index, 1)

        // let the word cloud know
        this.dispatchEventToWordCloud()
      }
    }
  },

  /**
   * As soon as the template has been rendered
   *
   * @returns {void}
   */
  created() {
    // subscribe to window event when the platform has been changed
    window.addEventListener("platformChange", this.handlePlatformChange)

    // subscribe to window event when the chip is closed
    window.addEventListener("removeFilter", this.handleRemoveFilter)
  },

  /**
   * As soon as the component is about to be deleted
   *
   * @returns {void}
   */
  beforeDestroy() {
    // unsubscribe to window event when the platform has been changed
    window.removeEventListener("platformChange", this.handlePlatformChange)

    // unsubscribe to window event when the chip is closed
    window.removeEventListener("removeFilter", this.handleRemoveFilter)
  }
}
</script>
